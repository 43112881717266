import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'JONNY BAIRSTOW',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'I’ve been training with FitClub for the past 3 months and I’ve had great results so far! Down 6% body fat and this is only the beginning.',
    name: 'JOHN WICK',
    status: 'CUSTOMER'
  },
  {
    image : image3,
    review:'place to workout, amazing trainers and customized workout plans.',
    name: 'TOM CRUISE',
    status: "CUSTOMER"
  }
];
